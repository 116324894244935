import { library } from "@fortawesome/fontawesome-svg-core";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons/faRightToBracket";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faChartSimple } from "@fortawesome/free-solid-svg-icons/faChartSimple";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faCashRegister } from "@fortawesome/free-solid-svg-icons/faCashRegister";
import { faIndustry } from "@fortawesome/free-solid-svg-icons/faIndustry";
import { faBoxesPacking } from "@fortawesome/free-solid-svg-icons/faBoxesPacking";
import { faBoxesStacked } from "@fortawesome/free-solid-svg-icons/faBoxesStacked";
import { faDolly } from "@fortawesome/free-solid-svg-icons/faDolly";
import { faCube } from "@fortawesome/free-solid-svg-icons/faCube";
import { faCubes } from "@fortawesome/free-solid-svg-icons/faCubes";
import { faRuler } from "@fortawesome/free-solid-svg-icons/faRuler";
import { faPalette } from "@fortawesome/free-solid-svg-icons/faPalette";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faUserShield } from "@fortawesome/free-solid-svg-icons/faUserShield";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons/faEllipsis";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faPencil } from "@fortawesome/free-solid-svg-icons/faPencil";
import { faRotate } from "@fortawesome/free-solid-svg-icons/faRotate";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import { faBox } from "@fortawesome/free-solid-svg-icons/faBox";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faGear } from "@fortawesome/free-solid-svg-icons/faGear";
import { faShieldHalved } from "@fortawesome/free-solid-svg-icons/faShieldHalved";
import { faPlugCircleCheck } from "@fortawesome/free-solid-svg-icons/faPlugCircleCheck";
import { faPlugCircleExclamation } from "@fortawesome/free-solid-svg-icons/faPlugCircleExclamation";
import { faUnlock } from "@fortawesome/free-solid-svg-icons/faUnlock";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons/faHandHoldingDollar";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons/faDollarSign";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons/faAddressBook";
import { faKey } from "@fortawesome/free-solid-svg-icons/faKey";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons/faFileInvoiceDollar";
import { faTruckRampBox } from "@fortawesome/free-solid-svg-icons/faTruckRampBox";
import { faReceipt } from "@fortawesome/free-solid-svg-icons/faReceipt";
import { faBan } from "@fortawesome/free-solid-svg-icons/faBan";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons/faCartShopping";
import { faTruckFast } from "@fortawesome/free-solid-svg-icons/faTruckFast";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons/faBoxOpen";
import { faFilter } from "@fortawesome/free-solid-svg-icons/faFilter";
import { faTags } from "@fortawesome/free-solid-svg-icons/faTags";
import { faStore } from "@fortawesome/free-solid-svg-icons/faStore";
import { faShirt } from "@fortawesome/free-solid-svg-icons/faShirt";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons/faClipboardCheck";
import { faTruck } from "@fortawesome/free-solid-svg-icons/faTruck";

library.add(
  faRightToBracket,
  faBars,
  faChartSimple,
  faCashRegister,
  faAngleUp,
  faAngleDown,
  faIndustry,
  faHandHoldingDollar,
  faBoxesPacking,
  faBoxesStacked,
  faDolly,
  faCube,
  faCubes,
  faRuler,
  faPalette,
  faUsers,
  faUserShield,
  faPlus,
  faMagnifyingGlass,
  faEllipsis,
  faPaperPlane,
  faTrash,
  faPencil,
  faRotate,
  faCircleInfo,
  faBox,
  faCheck,
  faGear,
  faShieldHalved,
  faPlugCircleCheck,
  faPlugCircleExclamation,
  faUnlock,
  faUserTie,
  faDollarSign,
  faAddressBook,
  faKey,
  faFileInvoiceDollar,
  faTruckRampBox,
  faReceipt,
  faBan,
  faTriangleExclamation,
  faCartShopping,
  faTruckFast,
  faEye,
  faBoxOpen,
  faFilter,
  faTags,
  faStore,
  faShirt,
  faClipboardCheck,
  faTruck
);
