export const toCapitalize = (words) =>
  words
    .split(" ")
    .map((word) =>
      word.charAt(0).toUpperCase().concat(word.substring(1).toLowerCase())
    )
    .join(" ");

export const validateField = (error, minLength = 0, maxLength = 0) => {
  if (error?.type === "validate" || error?.type === "pattern") {
    if (error?.ref?.name === "passwordC") {
      return "Las contraseñas no coinciden";
    }

    return "El dato ingresado es inválido";
  } else if (minLength > 0 && error?.type === "minLength") {
    return `Debe contener al menos ${minLength} de longitud`;
  } else if (error?.type === "required") {
    return "Este campo es requerido";
  } else if (maxLength !== 0 && error?.type === "maxLength") {
    return `No puede contener más de ${maxLength} de longitud`;
  }
};

export const validatePasswd = (errorType, passwd) => {
  if (errorType === "required") {
    return "Este campo es requerido";
  }

  if (errorType === "pattern") {
    if (!/(?=.*[a-z])/.test(passwd)) {
      return "La contraseña debe contener al menos una minúscula";
    } else if (!/(?=.*[A-Z])/.test(passwd)) {
      return "La contraseña debe contener al menos una mayúscula";
    } else if (!/(?=.*[0-9])/.test(passwd)) {
      return "La contraseña debe contener al menos un número";
    } else if (!/(?=.*[!@#$%^&*])/.test(passwd)) {
      return "La contraseña debe contener al menos un carácter especial";
    } else if (!/(?=.{8,})/.test(passwd)) {
      return `Debe contener al menos 8 caracteres`;
    }
  }
};

export const getCurrency = (value) =>
  value.toLocaleString("es-CO", {
    style: "currency",
    currency: "COP",
  });

export const getCleanCurrency = (value) =>
  value
    .toLocaleString("es-CO", {
      style: "currency",
      currency: "COP",
    })
    .replaceAll("$", "")
    .trim();

export const getNumberCurrency = (stringValue) => {
  const numberValue = parseFloat(
    stringValue.replaceAll(".", "").replaceAll(",", ".").replaceAll(/\s+/g, "")
  );

  return isNaN(numberValue) ? 0 : numberValue;
};

export const getStatuProps = (stateNumber) => {
  const status = {
    0: {
      name: "Creado",
      icon: "clipboard-check",
      color: "#0288d1",
    },
    1: {
      name: "Empacando",
      icon: "dolly",
      color: "#e67e22",
    },
    2: {
      name: "Despachando",
      icon: "truck",
      color: "#9b59b6",
    },
    3: {
      name: "Completado",
    },
  };

  return status[stateNumber];
};
