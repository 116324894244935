import { useEffect, useState, useCallback } from "react";
import {
  Box,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  Typography,
  CircularProgress,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MultipleSelect = ({
  primaryKey = "description",
  service,
  label,
  placeholder,
  defaultValue,
  initialValue,
  loadingMessage,
  errorMessage,
  disabled,
  handleChange,
}) => {
  const [selected, setSelected] = useState([...defaultValue]);
  const [options, setOptions] = useState([...initialValue]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [search, setSearch] = useState("");

  const getOptions = useCallback(
    async (search) => {
      try {
        setIsLoading(true);
        const res = await service.getAll(25, null, search);

        if (!res.data.success) {
          return;
        }

        setOptions([...res.data.result.items]);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [service]
  );

  const myHandleChange = (_, option) => {
    const {
      props: { value },
    } = option;

    if (!value) {
      return;
    }

    const checked = selected.findIndex((item) => item.id === value.id);

    setSelected(
      checked > -1
        ? [...selected.filter((item) => item.id !== value.id)]
        : [...selected, value]
    );

    handleChange(
      checked > -1
        ? [...selected.filter((item) => item.id !== value.id)]
        : [...selected, value]
    );
  };

  const onPressEnter = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  const onKeyPress = (e) => {
    e.stopPropagation();
  };

  const handleSearch = () => {
    getOptions(search);
  };

  useEffect(() => {
    if (options.length === 0 && !dirty) {
      setDirty(true);
      getOptions();
    }
  }, [options, dirty, getOptions]);

  useEffect(() => {
    setSelected([...defaultValue]);
  }, [defaultValue]);

  const classes = {
    selectInput: {
      "& input": {
        height: 10,
      },
    },
    progress: {
      ml: 2,
      verticalAlign: "text-bottom",
    },
  };

  return (
    <>
      {label && <InputLabel id="label">{label}</InputLabel>}
      <Select
        multiple
        labelId="label"
        defaultValue=""
        sx={classes.root}
        placeholder={placeholder ? placeholder : ""}
        onChange={myHandleChange}
        renderValue={(selection) =>
          selection.map((item) => item[primaryKey]).join(", ")
        }
        value={selected}
        disabled={disabled}
      >
        {isLoading ? (
          <Typography
            component="p"
            variant="subtitle1"
            fontSize="1.1rem"
            textAlign="center"
            mt={5}
            ml={-1}
          >
            {loadingMessage}{" "}
            <CircularProgress size={25} sx={classes.progress} />
          </Typography>
        ) : error ? (
          <Typography
            component="p"
            variant="subtitle1"
            color="error"
            fontSize="1.1rem"
            textAlign="center"
            mt={5}
            ml={-1}
          >
            {errorMessage}
          </Typography>
        ) : (
          [
            <FormControl
              onKeyDown={onKeyPress}
              key="searchInput"
              fullWidth
              sx={{ px: 1, mb: 2 }}
            >
              <TextField
                placeholder="Buscar"
                sx={classes.selectInput}
                onKeyDown={onPressEnter}
                autoComplete="off"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={handleSearch}>
                        <Box
                          component={FontAwesomeIcon}
                          icon="magnifying-glass"
                          fontSize="1rem"
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>,
            options.map((option) => (
              <MenuItem key={`option-${option.id}`} value={option}>
                <Checkbox
                  checked={
                    selected.findIndex((item) => item.id === option.id) > -1
                  }
                />
                <ListItemText primary={option[primaryKey]} />
              </MenuItem>
            )),
          ]
        )}
      </Select>
    </>
  );
};

export default MultipleSelect;
