import { useEffect, useContext, useMemo } from "react";
// import { useNavigate } from "react-router-dom";
import {
  Modal,
  Paper,
  Typography,
  Box,
  FormControl,
  FormHelperText,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContext } from "../../providers/ToastProvider";
import { DrawerContext } from "../../providers/DrawerProvider";
import { useForm } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import MultipleSelect from "../atoms/MultipleSelect";
import PackageService from "../../services/PackageService";
import DispatchService from "../../services/DispatchService";

const DispatchCreator = ({ open, handleClose, orderId }) => {
  // const navigate = useNavigate();
  const { setShowToast, setToastMessage } = useContext(ToastContext);
  const { drawerOpen } = useContext(DrawerContext);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      packages: [],
    },
  });
  const packageService = useMemo(() => new PackageService(), []);
  const dispatchService = useMemo(() => new DispatchService(), []);
  const theme = useTheme();
  const packages = watch("packages");

  const machete = {
    getAll: async () => {
      const res = await packageService.getAll(orderId);

      return {
        data: {
          result: {
            items: [...res.data.result.map((item, id) => ({ ...item, id }))],
          },
          success: true,
        },
      };
    },
  };

  const onSubmit = async (data) => {
    try {
      const packageIds = [...data.packages.map((item) => item.packageId)];

      await dispatchService.create(packageIds);

      setToastMessage([
        {
          severity: "success",
          primary: "Despacho creado exitosamente",
        },
      ]);
      setShowToast(true);
      handleClose();
    } catch (error) {
      if (
        error.response.data.error.message.includes(
          "has already been dispatched"
        )
      ) {
        setToastMessage([
          {
            severity: "error",
            primary: "Un paquete seleccionado ya se encuentra despachado",
          },
        ]);
      } else {
        setToastMessage([
          {
            severity: "error",
            primary: "Error creando despacho",
          },
        ]);
      }
      setShowToast(true);
    }
  };

  const onPackageSelect = (selected) => {
    setValue("packages", [...selected]);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    reset();
  }, [reset, open]);

  const classes = {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      left: drawerOpen ? theme.drawerWidth : 0,

      "& .MuiModal-backdrop": {
        left: drawerOpen ? theme.drawerWidth : 0,
      },
    },
    paper: {
      width: "90%",
      maxWidth: 400,
      textAlign: "center",
      position: "relative",
      top: -50,
      pt: 3,
      pb: 5,
      px: 6,
    },
    actions: {
      display: "flex",
      justifyContent: "center",
      gap: 2,
    },
  };

  return (
    <Modal open={open} onClose={handleClose} sx={classes.root}>
      <Paper onKeyDown={keyPress} sx={classes.paper}>
        <Box
          component="form"
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography variant="h6" component="h3" mb={3}>
            <Box component={FontAwesomeIcon} icon="truck" mr={2} />
            Crear despacho
          </Typography>
          <FormControl
            variant="filled"
            sx={[classes.inputControl, { mt: 2, width: 1 }]}
            {...register("packages", { required: true })}
            error={errors["packages"] !== undefined && packages.length === 0}
          >
            <MultipleSelect
              service={machete}
              label="Paquetes"
              defaultValue={packages}
              initialValue={[]}
              loadingMessage="Obteniendo paquetes"
              errorMessage="Error obteniendo los paquetes"
              disabled={false}
              handleChange={onPackageSelect}
              primaryKey="packageId"
            />
            {errors["packages"] !== undefined && packages.length === 0 && (
              <FormHelperText>Este campo es requerido</FormHelperText>
            )}
          </FormControl>
          <Box mt={5} sx={classes.actions}>
            <Button type="submit" variant="contained">
              Aceptar
            </Button>
            <Button variant="contained" color="error" onClick={handleClose}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default DispatchCreator;
