import { useEffect, useState, useCallback, useMemo, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Skeleton } from "@mui/material";
import { TopBarContext } from "../../providers/TopBarProvider";
import CustomNoRows from "../atoms/CustomNoRows";
import DispatchService from "../../services/DispatchService";
import DispatchItem from "../molecules/DispatchItem";

const Dispatches = () => {
  const [dispatches, setDispatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [orderId, setOrderId] = useState(undefined);
  const [error, setError] = useState(false);
  const { setTitle, setSearchOptions, setBtnPrimary } =
    useContext(TopBarContext);
  const dispatchService = useMemo(() => new DispatchService(), []);
  const rowsPerPage = 25;
  const location = useLocation();
  const navigate = useNavigate();

  const getAll = useCallback(
    async (orderId) => {
      try {
        setIsLoading(true);
        setDispatches([]);
        const res = await dispatchService.getAll(orderId);
        const items = res.data.result;

        if (items.length === 0) {
          return;
        }

        setDispatches([...items]);
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatchService, setDispatches]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const localOrderId = searchParams.get("id");

    setOrderId(localOrderId);

    getAll(localOrderId);
  }, [location, getAll, setOrderId]);

  useEffect(() => {
    setTitle(`Despachos de Orden #${orderId}`);
    setSearchOptions({
      action: () => null,
      hidden: true,
    });
    setBtnPrimary({
      action: null,
    });
  }, [setTitle, setSearchOptions, setBtnPrimary, orderId]);

  const classes = {
    root: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, 335px)",
      gridGap: 20,
      justifyContent: "center",
      my: 8,
    },
    skeleton: {
      width: "100%",
      height: 311,
    },
    customNoRows: {
      gridColumn: "6 span",
    },
    pagination: {
      width: "fit-content",
      margin: "0 auto",
    },
  };

  return (
    <>
      <Box sx={classes.root}>
        {isLoading ? (
          new Array(rowsPerPage)
            .fill(1)
            .map((_, idx) => (
              <Skeleton
                animation="wave"
                key={`skeleton-${idx}`}
                variant="rounded"
                sx={classes.skeleton}
              />
            ))
        ) : dispatches.length === 0 ? (
          <Box sx={classes.customNoRows}>
            <CustomNoRows
              message={
                error
                  ? "Error intentando obtener los paquetes"
                  : "No hay paquetes registrados"
              }
            />
          </Box>
        ) : (
          dispatches.map((dispatch) => {
            const dispatchId = dispatch.dispatchId;

            return (
              <DispatchItem
                key={`dispatch-${dispatchId}`}
                reference={String(dispatchId)}
                quantity={String(dispatch.packageCount)}
                handleOpen={() =>
                  navigate(`/orders/dispatches/dispatch?id=${dispatchId}`)
                }
                handleDelete={null}
              />
            );
          })
        )}
      </Box>
    </>
  );
};

export default Dispatches;
