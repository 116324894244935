import HttpService from "./HttpService";
import { api } from "../common/enviroment";

export default class DispatchService {
  httpService = new HttpService();

  getAll = (orderId) => {
    return this.httpService.get(
      `/services/app${api.DISPATCH_SERVICE}/GetAllDispatchesForOrder?orderId=${orderId}`
    );
  };

  get = (dispatchId) => {
    return this.httpService.get(
      `/services/app${api.DISPATCH_SERVICE}/GetDispatchDetails?dispatchId=${dispatchId}`
    );
  };

  create = (data) => {
    return this.httpService.post(
      `/services/app${api.DISPATCH_SERVICE}/CreateDispatchForOrder`,
      data
    );
  };
}
